<template>
  <div class="management linde-font mt-2">
    <v-card style="min-height: 500px;">
      <v-tabs
        ripple
        v-if="user && user.isAdmin"
        v-model="active"
        dark
        color="primary"
        hide-slider
      >
        <v-tab lazy ripple @click="setActive(0)">
          {{ constants.managementTabs.ADMIN.MEIN_KONTO.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <MyAccount />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(1)">
          {{ constants.managementTabs.ADMIN.NUTZERVERWALTUNG.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <UserManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(2)">
          {{ constants.managementTabs.ADMIN.EQUIPMENTVERWALTUNG.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <EquipmentManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(3)">
          {{ constants.managementTabs.ADMIN.RETURN_TRUCKS_VERWALTUNG.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <ReturnTruckManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(4)">
          {{ constants.managementTabs.ADMIN.ANFRAGENVERWALTUNG.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <InquiryManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(5)">
          {{ constants.managementTabs.ADMIN.GESUCHE.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <SearchInquiryManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(6)">
          {{ constants.managementTabs.ADMIN.INZAHLUNGNAHME.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <TradeInManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(7)">
          {{ constants.managementTabs.ADMIN.EMAIL_EINSTELLUNGEN.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <EmailManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(8)">
          {{ constants.managementTabs.ADMIN.ANSPRECHPARTNER_VERWALTUNG.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <ContactPersonManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(9)">
          {{ constants.managementTabs.ADMIN.PAPIERKORB.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <TrashedEquipmentManagement />
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-tabs
        ripple
        v-if="user && !user.isAdmin"
        v-model="active"
        dark
        color="primary"
        hide-slider
      >
        <v-tab lazy ripple @click="setActive(0)">
          {{ constants.managementTabs.SELLER.MEIN_KONTO.label }}
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <MyAccount />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(2)">
          {{ constants.managementTabs.SELLER.GESUCHE.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <SearchInquiryManagement />
          </v-card>
        </v-tab-item>
        <v-tab lazy ripple @click="setActive(3)">
          {{ constants.managementTabs.SELLER.INZAHLUNGNAHME.label }}
        </v-tab>
        <v-tab-item touchless>
          <v-card flat>
            <TradeInManagement />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import InquiryManagement from "@/components/management/InquiryManagement.vue";
import SearchInquiryManagement from "@/components/management/SearchInquiryManagement";
import UserManagement from "@/components/management/UserManagement.vue";
import EquipmentManagement from "@/components/management/EquipmentManagement.vue";
import ReturnTruckManagement from "@/components/management/ReturnTruckManagement.vue";
import EmailManagement from "@/components/management/EmailManagement.vue";
import MyAccount from "@/components/management/MyAccount.vue";
import TradeInManagement from "@/components/management/TradeInManagement";
import constants from "../utils/constants";
import TrashedEquipmentManagement from "@/components/management/TrashedEquipmentManagement.vue";
import ContactPersonManagement from "@/components/management/ContactPersonManagement.vue";
import {mapActions} from "vuex";

export default {
  name: "management",
  computed: {
    constants() {
      return constants
    }
  },
  data() {
    return {
      user: null,
      isLoggedIn: false,
      active: null
    };
  },
  components: {
    ContactPersonManagement,
    TrashedEquipmentManagement,
    TradeInManagement,
    InquiryManagement,
    SearchInquiryManagement,
    UserManagement,
    EquipmentManagement,
    ReturnTruckManagement,
    EmailManagement,
    MyAccount
  },
  methods: {
    ...mapActions(['getEquipmentData']),
    setActive(active) {
      if (active === 2) {
        this.getEquipmentData()
      }
      this.$store.commit("updateActiveTab", active);
    }
  },
  mounted() {
    this.isLoggedIn = this.$store.getters.loginState;
    this.active = this.$store.state.management.active;
    this.user = this.$store.state.user;
  },
  created() {
    //Scrolls to top when view is displayed
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped></style>
